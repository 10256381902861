export { merge, isEqual } from 'lodash'

export type ObjectValues<T> = T[keyof T]

export function isEmptyObject<T extends Object>(object: T | {}): object is {} {
  return Object.keys(object).length === 0
}

export function isNotEmptyObject<T extends Object>(object: T | {}): object is T {
  return Object.keys(object).length > 0
}
